import { useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label, Brush } from 'recharts';
import { Box, Radio, RadioGroup, FormControlLabel, Typography, Checkbox, FormGroup, Grid } from "@mui/material";

const EnergySourcesSplitAreaChart = ({ data }) => {
    const [dataType, setDataType] = useState("consumption"); // "count" or "livingSpace" or "consumption" or "co2Emissions"
    const [useClimateCleaned, setUseClimateCleaned] = useState(false);

    const dataTypeLabels = {
        count: "Gebäude [#]",
        livingSpace: "Wohnfläche [m²]",
        consumption: "Endenergieverbrauch [MWh]",
        co2Emissions: "CO₂-Emissionen [t]"
    };

    const dataTypeUnits = {
        count: "",
        livingSpace: "m²",
        consumption: "MWh",
        co2emissions: "t"
    };

    const renderLabel = (prop, dataKey) => {
        const index = prop.index;
        const _data = useClimateCleaned ? data.climateCleaned[dataType+"Percentage"] : data.regular[dataType+"Percentage"];
        const target = _data[index];
      
        if ((index % 4 === 1) && target[dataKey] > 10) {
            return (
                <text x={prop.x} y={prop.y} dy={17} fill="#888" fontSize={14} opacity={0.7} textAnchor="middle">
                {dataKey}
                </text>
            );
          }
        };
    
    return (
    
    <Box width="100%" height="40ch">
        <Grid container>
        <Box width="25%">
        <Typography variant="h4" sx={{ display: "flex", justifyContent: "left", marginLeft: "35%", mt: 1.3 }}>
            Energieträger
        </Typography>
        </Box>
        <Box width="75%">
        <Typography sx={{ display: "flex", justifyContent: "right", mt: 0.5, mr: "22%"}}>
            <RadioGroup
            row
            value={dataType}
            onChange={(event) => setDataType(event.target.value)}
            data-testid="energy-sources-split-chart-radio-group"
            >
                {/* <FormControlLabel
                    value="count"
                    control={<Radio />}
                    label="Gebäude"
                    data-testid="energy-sources-split-chart-radio-gebäude"
                />
                <FormControlLabel
                    value="livingSpace"
                    control={<Radio />}
                    label="Wohnfläche"
                    data-testid="energy-sources-split-chart-radio-wohnfläche"
                /> */}
                <FormControlLabel
                    value="consumption"
                    control={<Radio />}
                    label="Endenergieverbrauch"
                    data-testid="energy-sources-split-chart-radio-endenergieverbrauch"
                />
                <FormControlLabel
                    value="co2Emissions"
                    control={<Radio />}
                    label="CO₂-Emissionen"
                    data-testid="energy-sources-split-chart-radio-co2emissionen"
                />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox onChange={(event) => setUseClimateCleaned(event.target.checked)} />}
                        label="Witterungsbereinigt"
                        sx={{  ml: 2 }}
                    />
                </FormGroup>
            </RadioGroup>
        </Typography>
        </Box>
        </Grid>
        <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          syncId="essSyncId"
          width={1200}
          height={250}
          data={useClimateCleaned ? data.climateCleaned[dataType] : data.regular[dataType]}
          margin={{
            top: 10,
            right: 93,
            left: 60,
            bottom: 60,
          }}
          
        >
            <defs>
                <linearGradient id="colorStrom" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={"#8884D8"} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={"#8884D8"} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorFernwaerme" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={"#82CA9D"} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={"#82CA9D"} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorErdgas" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={"#FFB300"} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={"#FFB300"} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorHeizoel" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={"#000000"} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={"#000000"} stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorBiomasse" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={"#F0FE85"} stopOpacity={0.8}/>
                <stop offset="90%" stopColor={"#F0FE85"} stopOpacity={0.4}/>
                </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year">
                <Label value="Jahr" offset={-4} position="insideBottom" fontSize={16} />
            </XAxis>
            <YAxis>
                <Label value={dataTypeLabels[dataType]} offset={20} dx={10} dy={dataType === "consumption" ? -110 : -60} position="left" angle={-90} fontSize={16}  />
            </YAxis>
            <Tooltip itemSorter={(item) => -item.name}/>
            <Legend formatter={(val, entry) => <span style={{color: "#000"}}>{val}</span>} layout="vertical" verticalAlign="top" align="right" iconSize={24} fontSize={14}
            payload={[
                { value: 'Biomasse', type: 'line', id: 'Biomasse', color: "url(#colorBiomasse)" },
                { value: 'Heizöl', type: 'line', id: 'Heizöl', color: "url(#colorHeizoel)" },
                { value: 'Erdgas', type: 'line', id: 'Erdgas', color: "url(#colorErdgas)" },
                { value: 'Fernwärme', type: 'line', id: 'Fernwärme', color: "url(#colorFernwaerme)" },
                { value: 'Strom', type: 'line', id: 'Strom', color: "url(#colorStrom)" },
            ]} 
            wrapperStyle={{paddingLeft: '50px', marginTop: '30px' }}
            />
            <Area type="monotone" dataKey="Strom" stackId="1" stroke="#999" fill="url(#colorStrom)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={dataTypeUnits[dataType]}
            label={{content: props => renderLabel(props, "A+")}} />
            <Area type="monotone" dataKey="Fernwärme" stackId="1" stroke="#999" fill="url(#colorFernwaerme)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={dataTypeUnits[dataType]}
            label={{content: props => renderLabel(props, "A")}} />
            <Area type="monotone" dataKey="Erdgas" stackId="1" stroke="#999" fill="url(#colorErdgas)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={dataTypeUnits[dataType]}
            label={{content: props => renderLabel(props, "B")}} />
            <Area type="monotone" dataKey="Heizöl" stackId="1" stroke="#999" fill="url(#colorHeizoel)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={dataTypeUnits[dataType]} label={{content: props => renderLabel(props, "C")}} />
            <Area type="monotone" dataKey="Biomasse" stackId="1" stroke="#999" fill="url(#colorBiomasse)" fillOpacity={1} strokeOpacity={1} dot={{r:2}} unit={dataTypeUnits[dataType]}
            label={{content: props => renderLabel(props, "D")}} />
            <Brush syncId="essSyncId" y={250} height={20} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
    );
};

export default EnergySourcesSplitAreaChart;