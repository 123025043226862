// BarGraphs.js
import { React, useState } from "react";
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, FormGroup, Grid } from "@mui/material";
import BarChart from "./BarChart";
import { getTableDataFromUserResults, ResultDataTypes } from "../services/transformResultData";

const BarGraphs = ({ userResults, heightGraphs, bigAbsoluteUnits = true, singleBuilding = false }) => {
    const [emissionGraphDisplayType, setEmissionGraphDisplayType] = useState("absolut");

    const emissionRadioGroupHandleChange = (event) => {
        setEmissionGraphDisplayType(event.target.value);
    };

    const [climateCleaned, setClimateCleaned] = useState(false);

    return (
        <>
            <Box height={String(heightGraphs * 250) + "px"} width="100%" mt="10px">
                <Grid container>
                    <Box width="25%">
                        <Typography variant="h4" sx={{ display: "flex", justifyContent: "left", marginLeft: "35%", mt: 1.3 }}>
                        {"CO\u2082 Bilanz"}
                        </Typography>
                    </Box>
                    <Box width="75%">
                        <Typography sx={{ display: "flex", justifyContent: "right", mt: 0.5, mr: "22%"}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="co2-radio-buttons-group-label"
                                    name="co2-row-radio-buttons-group"
                                    onChange={emissionRadioGroupHandleChange}
                                    sx={{ mt: -0.8, ml: 3 }}
                                >
                                    <FormControlLabel
                                        value="absolut"
                                        control={<Radio />}
                                        checked={emissionGraphDisplayType === "absolut"}
                                        label="absolut"
                                    />
                                    <FormControlLabel value="spezifisch" control={<Radio />} label="spezifisch" />
                                </RadioGroup>
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox onChange={(event) => setClimateCleaned(event.target.checked)} />}
                                    label="Witterungsbereinigt"
                                    sx={{ mt: -0.8, ml: 3 }}
                                />
                            </FormGroup>
                        </Typography>
                    </Box>
                </Grid>
                <BarChart
                    isPortfolioAnalysis={true}
                    data={
                        singleBuilding
                            ? emissionGraphDisplayType === "absolut"
                                ? getTableDataFromUserResults(
                                      ResultDataTypes.SingleBuildingCo2Emissions,
                                      userResults,
                                      climateCleaned
                                  )
                                : getTableDataFromUserResults(
                                      ResultDataTypes.SingleBuildingCo2EmissionsPerSqm,
                                      userResults,
                                      climateCleaned
                                  )
                            : emissionGraphDisplayType === "absolut"
                            ? getTableDataFromUserResults(ResultDataTypes.Co2Emissions, userResults, climateCleaned)
                            : getTableDataFromUserResults(
                                  ResultDataTypes.Co2EmissionsPerSqm,
                                  userResults,
                                  climateCleaned
                              )
                    }
                    keys={[
                        "Solar",
                        "Raumwärme (Verbräuche)",
                        "Warmwasser (Verbräuche)",
                        "Raumwärme (Prognose)",
                        "Warmwasser (Prognose)",
                    ]}
                    label={
                        emissionGraphDisplayType === "absolut"
                            ? bigAbsoluteUnits
                                ? "t/Jahr"
                                : "kg/Jahr"
                            : "kg/(m\u00B2\u2217a)"
                    }
                    specific={emissionGraphDisplayType !== "absolut"}
                />
            </Box>
        </>
    );
};

export default BarGraphs;
