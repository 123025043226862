// BarGraphs.js
import { React, useState } from "react";
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, Grid } from "@mui/material";
import BarChart from "./BarChart";
import { getTableDataFromUserResults, ResultDataTypes } from "../services/transformResultData";

const ResultBarGraphs = ({
    userResults,
    heightGraphs,
    bigAbsoluteUnits = true,
    singleBuilding = false,
    isPortfolioAnalysis = true,
}) => {
    const [consumptionGraphDisplayType, setConsumptionGraphDisplayType] = useState("absolut");

    const consumptionRadioGroupHandleChange = (event) => {
        setConsumptionGraphDisplayType(event.target.value);
    };

    const [climateCleaned, setClimateCleaned] = useState(false);

    return (
        <>
            <Box height={String(heightGraphs * 250) + "px"} width="100%" mt="10px">
            <Grid container>
                    <Box width="25%">
                        <Typography variant="h4" sx={{ display: "flex", justifyContent: "left", marginLeft: "35%", mt: 1.3 }}>
                        Endenergiebilanz
                        </Typography>
                    </Box>
                    <Box width="75%">
                        <Typography sx={{ display: "flex", justifyContent: "right", mt: 0.5, mr: "22%"}}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="co2-radio-buttons-group-label"
                                    name="co2-row-radio-buttons-group"
                                    onChange={consumptionRadioGroupHandleChange}
                                    sx={{ mt: -0.8, ml: 3 }}
                                >
                                    <FormControlLabel
                                        value="absolut"
                                        control={<Radio />}
                                        checked={consumptionGraphDisplayType === "absolut"}
                                        label="absolut"
                                    />
                                    <FormControlLabel value="spezifisch" control={<Radio />} label="spezifisch" />
                                </RadioGroup>
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox onChange={(event) => setClimateCleaned(event.target.checked)} />}
                                    label="Witterungsbereinigt"
                                    sx={{ mt: -0.8, ml: 3 }}
                                />
                            </FormGroup>
                        </Typography>
                    </Box>
                </Grid>
                <BarChart
                    isPortfolioAnalysis={isPortfolioAnalysis}
                    data={
                        singleBuilding
                            ? consumptionGraphDisplayType === "absolut"
                                ? getTableDataFromUserResults(
                                      ResultDataTypes.SingleBuildingGrossEnergyConsumption,
                                      userResults,
                                      climateCleaned
                                  )
                                : getTableDataFromUserResults(
                                      ResultDataTypes.SingleBuildingGrossEnergyConsumptionPerSqm,
                                      userResults,
                                      climateCleaned
                                  )
                            : consumptionGraphDisplayType === "absolut"
                            ? getTableDataFromUserResults(
                                  ResultDataTypes.GrossEnergyConsumption,
                                  userResults,
                                  climateCleaned
                              )
                            : getTableDataFromUserResults(
                                  ResultDataTypes.GrossEnergyConsumptionPerSqm,
                                  userResults,
                                  climateCleaned
                              )
                    }
                    keys={[
                        "Solar",
                        "Raumwärme (Verbräuche)",
                        "Warmwasser (Verbräuche)",
                        "Raumwärme (Prognose)",
                        "Warmwasser (Prognose)",
                    ]}
                    label={
                        consumptionGraphDisplayType === "absolut"
                            ? bigAbsoluteUnits
                                ? "MWh/Jahr"
                                : "kWh/Jahr"
                            : "kWh/(m\u00B2\u2217a)"
                    }
                    specific={consumptionGraphDisplayType !== "absolut"}
                    consumption={true}
                />
            </Box>
        </>
    );
};

export default ResultBarGraphs;
