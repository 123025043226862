import { useState, useCallback, useEffect } from "react";
import { Bar, BarChart, XAxis, YAxis, Tooltip, Legend, Cell, CartesianGrid, Label, LabelList, ResponsiveContainer  } from "recharts";
import { Box, Radio, RadioGroup, FormControlLabel, Typography, Checkbox, FormGroup, Divider } from "@mui/material";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

const EnergySourcesSplitBarChart = ({ data }) => {
    const [useClimateCleaned, setUseClimateCleaned] = useState(false);
    const [useRadial, setUseRadial] = useState(true);
    const [chartData, setChartData] = useState(data.regular);

    const colors = {
        Strom: "#8884D8",
        Fernwärme: "#82CA9D",
        Erdgas: "#FFB300",
        Heizöl: "#000000",
        Biomasse: "#F0FE85",
    }

    const RadialBarChart = ({ data }) => {
        const chartTheme = {
            // background: "#ffffff",
            textColor: "#333333",
            fontSize: 13,
            axis: {
                domain: {
                    line: {
                        stroke: "#777777",
                        strokeWidth: 0,
                    },
                },
                legend: {
                    text: {
                        fontSize: 16,
                        fill: "#000000",
                    },
                },
                ticks: {
                    line: {
                        stroke: "#777777",
                        strokeWidth: 2,
                    },
                    text: {
                        fontSize: 13,
                        fill: "#333333",
                    },
                },
            },
            grid: {
                line: {
                    stroke: "#dddddd",
                    strokeWidth: 1,
                },
            },
            legends: {
                title: {
                    text: {
                        fontSize: 11,
                        fill: "#333333",
                    },
                },
                text: {
                    fontSize: 13,
                    fill: "#333333",
                },
                ticks: {
                    line: {},
                    text: {
                        fontSize: 10,
                        fill: "#333333",
                    },
                },
            },
            annotations: {
                text: {
                    fontSize: 13,
                    fill: "#333333",
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1,
                },
                link: {
                    stroke: "#000000",
                    strokeWidth: 1,
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1,
                },
                outline: {
                    stroke: "#000000",
                    strokeWidth: 2,
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1,
                },
                symbol: {
                    fill: "#000000",
                    outlineWidth: 2,
                    outlineColor: "#ffffff",
                    outlineOpacity: 1,
                },
            },
            tooltip: {
                container: {
                    background: "#ffffff",
                    color: "#333333",
                    fontSize: 12,
                },
                basic: {},
                chip: {},
                table: {},
                tableCell: {},
                tableCellValue: {},
            },
        };


        return (
            <ResponsiveRadialBar
                    height={400}
                    width={500}
                    data={data}
                    valueFormat=">-.0f"
                    startAngle={0}
                    endAngle={270}
                    innerRadius={0.2}
                    padding={0.45}
                    margin={{ top: 40, right: 60, bottom: 70, left: -60 }}
                    colors={[colors.Strom, colors.Fernwärme,  colors.Erdgas, colors.Heizöl, colors.Biomasse,]}
                    borderWidth={0}
                    borderColor="black"
                    enableRadialGrid={true}
                    enableCircularGrid={false}
                    radialAxisStart={{ tickSize: 8, tickPadding: 4, tickRotation: 0 }}
                    circularAxisOuter={{ tickSize: 8, tickPadding: 12, tickRotation: 0 }}
                    theme={chartTheme}
                    legends={[
                        {
                            anchor: "right",
                            direction: "column",
                            justify: false,
                            translateX: 25,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: "left-to-right",
                            itemWidth: 66,
                            itemHeight: 37,
                            itemTextColor: "#000",
                            symbolSize: 25,
                            symbolShape: "square",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemTextColor: "#000",
                                    },
                                },
                            ],
                        },
                    ]}
                />
        );
    };

    const transformDataForRadialBar = (data) => {
        const energySourceOrder = {
            Strom: 0,
            Fernwärme: 1,
            Erdgas: 2,
            Heizöl: 3,
            Biomasse: 4,
            Holz: 4,
        };
        var res = [{id: "Endenergieverbrauch [%]", data: [{},{},{},{},{}]}, {id: "CO₂-Emissionen [%]", data: [{},{},{},{},{}]}];
        Object.keys(data).forEach((key) => {
            // obj = {name: "Strom", "Endenergieverbrauch [%]": 10, "CO₂-Emissionen [%]": 20, "Wohnfläche [%]": 30, "Gebäude [%]": 40}
            res[0].data.splice(energySourceOrder[data[key].name], 1, {x: data[key].name, y: data[key]["Endenergieverbrauch [%]"]});
            res[1].data.splice(energySourceOrder[data[key].name], 1, {x: data[key].name, y: data[key]["CO₂-Emissionen [%]"]});
        });
        return res;
    };

    useEffect(() => {
        setChartData(useClimateCleaned ? data.climateCleaned : data.regular);
        setRadialChartData(transformDataForRadialBar(useClimateCleaned ? data.climateCleaned : data.regular));
    }, [useClimateCleaned, data.climateCleaned, data.regular]);

    const [radialChartData, setRadialChartData] = useState(transformDataForRadialBar(data.regular));

    return (
    
    <Box height="62ch" width="84ch">
        <Typography variant="h4" sx={{ display: "flex", justifyContent: "center", marginLeft: "-7%", mb: 0 }}>
            Energieträgerübersicht
        </Typography>
        <Typography sx={{ display: "flex", justifyContent: "center"}}>
            <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox onChange={(event) => setUseRadial(!event.target.checked)} />}
                        label="Balkendiagramm"
                        sx={{  ml: 0 }}
                    />
                    <FormControlLabel
                        control={<Checkbox onChange={(event) => {setUseClimateCleaned(event.target.checked)}} />}
                        label="Witterungsbereinigt"
                        sx={{  ml: 0 }}
                    />
                </FormGroup>
        </Typography>
        {useRadial ? 
        <RadialBarChart data={radialChartData} />
        : 
        <BarChart width={600} height={350} data={chartData} layout="vertical" margin={{ top: 20, left: 20 }} barCategoryGap={12} barGap={1}>
            <YAxis dataKey="name" type="category" angle={30}>
            </YAxis>
            <XAxis xAxisId="bottom" orientation="bottom" stroke="#000000" type="number" domain={[0, 100]} />
            <Tooltip />
            <Legend wrapperStyle={{paddingLeft: '22px' }}/>
            <CartesianGrid strokeDasharray="3 3" />
            <Bar xAxisId="bottom" dataKey={"Endenergieverbrauch [%]"} fill={"#82ca9d"} unit={"%"}/>
            <Bar xAxisId="bottom" dataKey={"CO₂-Emissionen [%]"} fill={"#8884d8"} unit={"%"}/>
            <Bar xAxisId="bottom" dataKey={"Wohnfläche [%]"} fill={"#2ba6c4"} unit={"%"}/>
            <Bar xAxisId="bottom" dataKey={"Gebäude [%]"} fill={"#032C58"} unit={"%"} />
        </BarChart>
        }
    </Box>
    );
};

export default EnergySourcesSplitBarChart;