import { useState } from "react";
import { Bar, BarChart, XAxis, YAxis, Tooltip, Legend, Cell, CartesianGrid, Label, LabelList } from "recharts";
import { Box, Radio, RadioGroup, FormControlLabel, Typography, Checkbox, FormGroup } from "@mui/material";

const EnergyEfficiencyClassesHorizontalBarChart = ({ data }) => {
    const [usePercentage, setUsePercentage] = useState(false);
    const [useClimateCleaned, setUseClimateCleaned] = useState(false);

    return (
    
    <Box height="62ch" width="80ch">
        <Typography variant="h4" sx={{ display: "flex", justifyContent: "center", marginLeft: "-7%", mb: 0 }}>
            Energieeffizienzklassen
        </Typography>
        <Typography sx={{ display: "flex", justifyContent: "center"}}>
            <RadioGroup
            row
            value={usePercentage ? "prozentual" : "absolut"}
            onChange={(event) => setUsePercentage(event.target.value === "prozentual")}
            data-testid="energy-efficiency-chart-radio-group"
            >
                <FormControlLabel
                    value="absolut"
                    control={<Radio />}
                    label="absolut"
                    data-testid="energy-efficiency-chart-radio-absolut"
                />
                <FormControlLabel
                    value="prozentual"
                    control={<Radio />}
                    label="prozentual"
                    data-testid="energy-efficiency-chart-radio-prozentual"
                />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox onChange={(event) => setUseClimateCleaned(event.target.checked)} />}
                        label="Witterungsbereinigt"
                        sx={{  ml: 2 }}
                    />
                </FormGroup>
            </RadioGroup>
        </Typography>
        <BarChart width={600} height={360} data={useClimateCleaned ? data.climateCleaned : data.regular} layout="vertical" margin={{ top: -5 }} barCategoryGap={5} barGap={1}>
            <YAxis dataKey="name" type="category" tickMargin={4}>
            </YAxis>
            <XAxis xAxisId="top" orientation="top" stroke="#8884d8" type="number" domain={usePercentage ? [0, 100] : ['auto', 'auto']} />
            <XAxis xAxisId="bottom" orientation="bottom" stroke="#82ca9d" type="number" domain={usePercentage ? [0, 100] : ['auto', 'auto']} />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar xAxisId="top" dataKey={usePercentage ? "Gebäude [%]" : "Gebäude [#]"} fill={"#8884d8"} unit={usePercentage ? "%" : ""}>
            </Bar>
            <Bar xAxisId="bottom" dataKey={usePercentage ? "Wohnfläche [%]" : "Wohnfläche [m²]"} fill={"#82ca9d"} unit={usePercentage ? "%" : "m²"}>
            </Bar>
        </BarChart>
    </Box>
    );
};

export default EnergyEfficiencyClassesHorizontalBarChart;